import { DOCUMENT } from '@angular/common'
import { Inject, Injectable } from '@angular/core'
import { Observable, ReplaySubject } from 'rxjs'
import { tap } from 'rxjs/operators'

@Injectable({ providedIn: 'root' })
export class LoaderService {
  private _loadedLibraries: { [url: string]: ReplaySubject<any> } = {}

  constructor(
    @Inject(DOCUMENT) private readonly document: any
  ) { }

  /**
   * Faz o carregamento sob demanda da biblioteca externa
   * @param url
   */
  script(url: string): Observable<any> {
    /** Caso o script já tenha sido carregado, devolve o que já existe **/
    if (this._loadedLibraries[url]) {
      return this._loadedLibraries[url].asObservable()
    }
    this._loadedLibraries[url] = new ReplaySubject()
    const script = this.document.createElement('script')
    script.type = 'text/javascript'
    script.async = true
    script.src = url
    script.onload = () => {
      this._loadedLibraries[url].next()
      this._loadedLibraries[url].complete()
    }
    this.document.body.appendChild(script)
    return this._loadedLibraries[url].asObservable().pipe(tap(() => null/*!env.production && console.log(`LOADED SCRIPT ${url}`)*/));
  }

  /**
   * Faz o carregamento sob demanda da css externa
   * @param url
   */
  stylesheet(url: string): Observable<any> {
    /** Caso o style já tenha sido carregado, devolve o que já existe **/
    if (this._loadedLibraries[url]) {
      return this._loadedLibraries[url].asObservable()
    }
    this._loadedLibraries[url] = new ReplaySubject()
    const link = this.document.createElement('link')
    link.rel = 'stylesheet'
    link.async = true
    link.href = url
    link.onload = () => {
      this._loadedLibraries[url].next()
      this._loadedLibraries[url].complete()
    }
    // console.log(this.document.body, link)
    this.document.body.appendChild(link)
    return this._loadedLibraries[url].asObservable().pipe(tap(() => null/*!env.production && console.log(`LOADED STYLE ${url}`)*/))
  }

  style(nick: string, url: string = null) {
    this.stylesheet(url || `assets/${nick}/styles.css`).subscribe(() => null)
  }

  font(font) {
    // console.log(`https://fonts.googleapis.com/css2?family=${font}&display=swap`)
    this.stylesheet(`https://fonts.googleapis.com/css2?family=${font}&display=swap`).subscribe((res) =>    {
      // console.log(res)
    })
  }
}
