import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', loadChildren: () => import('./home/home.module').then(m => m.SiteHomeModule) },
  { path: 'grid', loadChildren: () => import('./grid/grid.module').then(m => m.GridModule), },
  { path: 'blog', loadChildren: () => import('./blog/blog.module').then(m => m.BlogModule), },
  { path: 'slider', loadChildren: () => import('./slider/slider.module').then(m => m.SliderModule), },
  { path: 'video', loadChildren: () => import('./video/video.module').then(m => m.VideoModule) },
  { path: 'contact', loadChildren: () => import('./contact/contact.module').then(m => m.ContactModule) },
  { path: 'salesmanago', loadChildren: () => import('./salesmanago/salesmanago.module').then(m => m.SalesManagoModule) }
]

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
    // enableTracing: true,
    scrollPositionRestoration: 'enabled'
  })
],
  exports: [RouterModule]
})

export class AppRoutingModule { static routes = routes }
