export const env = {

  production: false,
  pp: false,
  id: 'yes4eutests',
  region: 'europe-west3',

  _yesKey: 'dGhlIG9sYXJpYSBzZXJ2aWNlIGtleQ==',

  // yes4eutests
  fb: {
    apiKey: "AIzaSyCqSOQwtaED9QTOg_IqZxOKgF5N89zO2lQ",
    authDomain: "yes4eutests.firebaseapp.com",
    projectId: "yes4eutests",
    storageBucket: "yes4eutests.appspot.com",
    messagingSenderId: "1062997613231",
    appId: "1:1062997613231:web:a93d4d8f5b49f3ab41125a",
    measurementId: "G-T322ZYB8NT",
    persistence: false,
  },
  
  apiUrl: `https://firestore.googleapis.com/v1/projects/yes4eutests/databases/(default)/documents`,

  _cryKey: 'JpYS',

  stripePublishable: 'pk_test_8WLlgoMFsLnKWW1oQG7BVHQ8',

  algolia: {
    appId: '3M8JWUF0PM',
    apiKey: '5501a4a9e7f84050a292cdb5fa22bb12'
  },

  infobip: {
    url: 'https://api.infobip.com/'
  },

  _yesSalt: 'dGhlIG9sYXJpYSBzYWx0ZSBrZXkgc2VydmljZQ==',

  easyPayUrl: 'https://api.test.easypay.pt/2.0/',
  easyPayId: '7cb9b8de-28f6-4c87-aca0-f2afb0f733d7',
  easyPayKey: '6a32abbe-f69e-4693-a010-90605061ceb1',

  webPushKey: 'BE8ieIBoxsi_paCZKszWDmOEXZ-ttqclJbDI55pc88vUAZY2B8xqKbLBCpWyCOMThCLfIjbbSrmyDkLQDCelMLk',

  bulkSMS: {
    name: 'yes4eutests',
    url: 'https://api.bulksms.com/v1',
    auth: 'Basic NjM5RUZBNDU3NTI1NDNCNzkxNEI3MjVBMkQ0MTM2NzYtMDItQjpYdyNfKktiOVRMaTNqb0JDOFV4VmpIVXhmS0NQZg=='
  }

};
