import { GlobalService } from './core/global.service';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule, BrowserTransferStateModule } from '@angular/platform-browser';
import { env } from '../environments/environment'
import { AppComponent } from './app.component';
import { NavSiteModule } from './nav/nav.module';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
// import { DOCUMENT } from '@angular/common'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppInitService } from './app-init.service';

export function init(appInitService: AppInitService) {
  return (): Promise<any> => {
    return appInitService.init();
  }
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    provideFirebaseApp(() => initializeApp(env.fb)),
    provideFirestore(() => getFirestore()),
    BrowserTransferStateModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    NavSiteModule,
    HttpClientModule,
  ],
  providers: [
    { provide: APP_INITIALIZER, useFactory: init, deps: [AppInitService], multi: true },
  ],
  bootstrap: [AppComponent]
})

export class AppModule {
  constructor(private g: GlobalService) {
    console.log('# ID', env.id);
    this.g.set('env', env);
  }
}
