<nav class="fixed-top navbar navbar-expand-lg p-0 bg-white">
  <div class="container-fluid">
    <a class="navbar-brand" routerLink="/home">
      <logo-component></logo-component>
    </a>
    <!-- <span class="signature text-center">
      where marketing meets technology
    </span> -->
    <button type="button" class="navbar-toggler" data-bs-toggle="collapse" data-bs-target=".navbar-collapse">
      <span class="navbar-toggler-icon"><i class="fas fa-2x fa-bars"></i></span>
    </button>

    <div class="collapse navbar-collapse justify-content-between" id="navbarCollapse">
      <form class="d-flex">
        <!-- <div class="input-group">
            <input type="text" class="form-control" placeholder="Search">
            <button type="button" class="btn btn-secondary"><i class="bi-search"></i></button>
          </div> -->
      </form>
      <div class="navbar-nav float-center logo">

      </div>
      <div class="navbar-nav float-end">
        <!-- <a class="nav-item nav-link" routerLink="/home"> <i class="fas fa-home"></i> </a> -->
        <a class="nav-item nav-link m-1" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show" routerLinkActive="active" routerLink="/grid/clients"> CLIENTES</a>
        <a class="nav-item nav-link m-1" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show" routerLinkActive="active" routerLink="/slider/presentations/yes4web/jpg/1/22"> YES4WEB</a>
        <a class="nav-item nav-link m-1" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show" routerLinkActive="active" routerLink="/slider/presentations/yescard/jpg/1/4"> YES CARD</a>
        <a class="nav-item nav-link m-1" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show" routerLinkActive="active" routerLink="/slider/presentations/portfolio/jpg/4/9"> PORTFÓLIO</a>
        <a class="nav-item nav-link m-1" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show" routerLinkActive="active" routerLink="/salesmanago"> SALES MANAGO</a>
        <a class="nav-item nav-link m-1" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show" routerLinkActive="active" routerLink="/blog"> BLOG</a>
        <a class="nav-item nav-link m-1" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show" routerLinkActive="active" routerLink="/grid/team"> EQUIPA</a>
        <a class="nav-item nav-link m-1" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show" routerLinkActive="active" routerLink="/contact"> CONTACTOS</a>
      </div>
    </div>

  </div>

</nav>




<div *ngIf="g.program?.stage==='preparation' && !isDev">
  <h1 class="badge is-large btn-danger">SITE EM MANUTENÇÃO. POR FAVOR, VOLTE MAIS TARDE</h1>
</div>