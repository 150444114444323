import { LogoModule } from './logo/logo.module';
import { TranslocoModule } from '@ngneat/transloco';
// import { ChatModule } from '@shared/chat/module';
// import { NavAdminModule } from '@admin/nav/nav.module';
import { NavSiteComponent } from './nav.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
// import { NotificationModule } from '@shared/notification-dropdown/notification-dropdown.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { DeviceDetectorModule } from 'ngx-device-detector';

@NgModule({
  declarations: [NavSiteComponent],
  imports: [
    CommonModule,
    RouterModule ,
    LogoModule,
    // NavAdminModule,
    // NotificationModule,
    TranslocoModule,
    // ChatModule,
    FormsModule,
    ReactiveFormsModule,
    // DeviceDetectorModule.forRoot()
  ],
  exports: [NavSiteComponent],
})
export class NavSiteModule { }
