
import { GlobalService } from '../core/global.service';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { isDevMode } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { SubSink } from "subsink";
import { DOCUMENT } from '@angular/common';
@Component({
  selector: 'site-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.sass']
})

export class NavSiteComponent implements OnInit, OnDestroy {
  isDev;
  private subs = new SubSink();

  constructor(
    public deviceService: DeviceDetectorService,
    public g: GlobalService,
    @Inject(DOCUMENT) private readonly document: any
  ) {
    // console.log('NavSiteComponent - constructor');
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  ngOnInit() {
    // console.log('NavSiteComponent init')
    this.isDev = isDevMode()
    const navbarCollapse = this.document.getElementById('navbarCollapse')
    navbarCollapse.addEventListener('hidden.bs.collapse', function () {
      // console.log('collapsed')
    })
    navbarCollapse.addEventListener('hide.bs.collapse', function () {
      // console.log('collapse')
    })
    navbarCollapse.addEventListener('show.bs.collapse', function () {
      // console.log('show')
    })
    navbarCollapse.addEventListener('shown.bs.collapse', function () {
      // console.log('shown')
    })
  }
}
